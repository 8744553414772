import env from '@env';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Container from '../Container';
import Text from '../Text';
import FooterStyles from './styles';

const Footer: React.FC = () => {
  const email = env().NEXT_PUBLIC_CONTACT_EMAIL;
  const year = new Date().getFullYear();

  return (
    <FooterStyles.Footer>
      <Container>
        <Text.Paragraph fontSize="px14" textAlign="center" marginBottom="px16" className="text-pui-paragraph-900">
          <FormattedMessage
            id="footer.copyright"
            values={{
              bold: (msg: string) => <Text.Span fontWeight="bold">{msg}</Text.Span>,
              email: () => (
                <Text.Link href={`mailto:${email}`} rel="noopener noreferrer" color="primary">
                  {email}
                </Text.Link>
              ),
              year
            }}
          />
        </Text.Paragraph>
      </Container>
    </FooterStyles.Footer>
  );
};

export default React.memo(Footer);
