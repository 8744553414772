import styled from 'styled-components';
import { HeaderProps } from './';

export default {
  Container: styled.div<HeaderProps>`
    position: relative;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    z-index: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 2;
      background: url(${(props) => props.headerImage}) ${(props) => props.positionHorizontal || 'center'}
        ${(props) => props.positionVertical || 'center'} / cover no-repeat;
      background-color: ${(props) => props.theme.colors.background};
    }
  `,
  Content: styled.div<{ minHeight?: string }>`
    position: inherit;
    top: 0;
    display: flex;
    align-items: center;
    width: 100px;
    z-index: 3;
    height: 20vh;
    width: 80%;
    ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : ``)};
  `
};
