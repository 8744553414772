import Head from 'next/head';
import React from 'react';
import { useIntl } from 'react-intl';
import Footer from 'src/components/UI/Footer';
import GoogleTagManager from '../../UI/GoogleTagManager';
import Header from '../../UI/Header';

type LayoutProps = {
  googleTagManagerId?: string;
};

const Layout: React.FC<LayoutProps> = ({ children, googleTagManagerId }) => {
  const intl = useIntl();
  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
        <title>{intl.formatMessage({ id: 'pageTitle' })}</title>
      </Head>

      <Header />

      <GoogleTagManager googleTagManagerId={googleTagManagerId} />

      <main>{children}</main>

      <Footer />
    </>
  );
};

export default Layout;
