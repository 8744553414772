import { css } from 'styled-components';
import { responsiveMediaCss } from '../../../utils/mediaQueryUtils';
import { Globals, DisplayOutside, DisplayInside, DisplayInternal, DisplayLegacy } from 'csstype';

// Equivalent of csstype.DisplayProperty but without "string".
// Because string forces it to always be string, since it'll get the less specific type
type ValueType =
  | Globals
  | DisplayOutside
  | DisplayInside
  | DisplayInternal
  | DisplayLegacy
  | 'contents'
  | 'list-item'
  | 'none';

export type DisplayProps = {
  display?: ValueType;
  ['sm-display']?: ValueType;
  ['md-display']?: ValueType;
  ['lg-display']?: ValueType;
  ['xl-display']?: ValueType;
};

const getDisplay = (display: ValueType) => css`
  display: ${display};
`;

export const displayCss = css<DisplayProps>`
  ${(props) => props.display && getDisplay(props.display)}
  ${(props) => props['sm-display'] && responsiveMediaCss(getDisplay(props['sm-display']), 'mobile')}
  ${(props) => props['md-display'] && responsiveMediaCss(getDisplay(props['md-display']), 'tablet')}
  ${(props) => props['lg-display'] && responsiveMediaCss(getDisplay(props['lg-display']), 'laptop')}
  ${(props) => props['xl-display'] && responsiveMediaCss(getDisplay(props['xl-display']), 'desktop')}
`;
