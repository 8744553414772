import styled from 'styled-components';
import helpers from '../../../styles/helpers';
import ResponsiveMediaQuery from '../ResponsiveMediaQuery';

export default {
  Footer: styled.div`
    height: 165px;
    margin-bottom: 0;
    background: ${(props) => props.theme.colors.background};
    align-items: center;
    display: flex;
    padding: ${(props) => props.theme.spacing.px32};
    ${ResponsiveMediaQuery('tablet')} {
      padding: ${(props) => props.theme.spacing.px8} 0;
    }

    a {
      text-decoration: none;
      color: ${(props) => props.theme.colors.primary};
    }
    a:hover {
      text-decoration: underline;
    }
    ${helpers}
  `
};
