import { css } from 'styled-components';
import { responsiveMediaCss } from '../../../utils/mediaQueryUtils';
import { FontWeightProperty } from 'csstype';

type ValueType = FontWeightProperty;

export type FontWeightProps = {
  fontWeight?: ValueType;
  ['sm-fontWeight']?: ValueType;
  ['md-fontWeight']?: ValueType;
  ['lg-fontWeight']?: ValueType;
  ['xl-fontWeight']?: ValueType;
};

const getFontWeight = (fontWeight: ValueType) => css`
  font-weight: ${fontWeight};
`;

export const fontWeightCss = css<FontWeightProps>`
  ${(props) => props.fontWeight && getFontWeight(props.fontWeight)}
  ${(props) => props['sm-fontWeight'] && responsiveMediaCss(getFontWeight(props['sm-fontWeight']), 'mobile')}
  ${(props) => props['md-fontWeight'] && responsiveMediaCss(getFontWeight(props['md-fontWeight']), 'tablet')}
  ${(props) => props['lg-fontWeight'] && responsiveMediaCss(getFontWeight(props['lg-fontWeight']), 'laptop')}
  ${(props) => props['xl-fontWeight'] && responsiveMediaCss(getFontWeight(props['xl-fontWeight']), 'desktop')}
`;
