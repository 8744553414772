import { css } from 'styled-components';
import { responsiveMediaCss } from '../../../utils/mediaQueryUtils';
import { Globals } from 'csstype';

// Equivalent of csstype.FontStyleProperty but without "string".
// Because string forces it to always be string, since it'll get the less specific type
type ValueType = Globals | 'italic' | 'normal' | 'oblique';

export type FontStyleProps = {
  fontStyle?: ValueType;
  ['sm-fontStyle']?: ValueType;
  ['md-fontStyle']?: ValueType;
  ['lg-fontStyle']?: ValueType;
  ['xl-fontStyle']?: ValueType;
};

const getFontStyle = (fontStyle: ValueType) => css`
  font-style: ${fontStyle};
`;

export const fontStyleCss = css<FontStyleProps>`
  ${(props) => props.fontStyle && getFontStyle(props.fontStyle)}
  ${(props) => props['sm-fontStyle'] && responsiveMediaCss(getFontStyle(props['sm-fontStyle']), 'mobile')}
  ${(props) => props['md-fontStyle'] && responsiveMediaCss(getFontStyle(props['md-fontStyle']), 'tablet')}
  ${(props) => props['lg-fontStyle'] && responsiveMediaCss(getFontStyle(props['lg-fontStyle']), 'laptop')}
  ${(props) => props['xl-fontStyle'] && responsiveMediaCss(getFontStyle(props['xl-fontStyle']), 'desktop')}
`;
