import React from 'react';
import HeaderStyles from './styles';

export type HeaderProps = React.PropsWithChildren<{
  headerImage?: string;
  positionHorizontal?: 'left' | 'center' | 'right' | undefined;
  positionVertical?: 'top' | 'center' | 'bottom' | undefined;
  minHeight?: string;
  gradient?: boolean;
  noBackground?: boolean;
}>;

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <HeaderStyles.Container
      headerImage={props.headerImage}
      positionHorizontal={props.positionHorizontal}
      positionVertical={props.positionVertical}
    >
      <HeaderStyles.Content minHeight={props.minHeight}>
        <img src={'/static/icons/logo.svg'} alt="Site logo" />
        {/* {props.children} */}
      </HeaderStyles.Content>
    </HeaderStyles.Container>
  );
};

export default Header;
